<script>
  import { mdiEyeOffOutline, mdiEyeOutline } from "@mdi/js";
  import { defineComponent, ref } from "@vue/composition-api";
  import { pwnedPassword } from "hibp";

  import { snackbar } from "@/components/base/snackbar/useSnackbar";
  import { useRouter } from "@/helpers";
  import { store } from "@/store";
  import themeConfig from "@/themeConfig";

  export default defineComponent({
    setup() {
      // Template Ref
      // const loginForm = ref(null);
      const { router } = useRouter();
      const isPasswordVisible = ref(false);
      const email = ref(null);
      const password = ref(null);
      //const awaitingSearch = ref(false);
      const loading = ref(false);
      const showPwnWarning = ref(false);
      const redirectOnSuccess = ref(false);

      const handlePwnWarning = () => {
        if (showPwnWarning.value == true) {
          router.push({ name: "apps-user-view" });
          showPwnWarning.value == false;
        }
      };
      const handleFormSubmit = (email, password) => {
        loading.value = true;
        store
          .dispatch("auth/authenticate", {
            strategy: "local",
            email,
            password
          })
          .then(() => {
            pwnedPassword(password)
              .then((numPwns) => {
                console.log(numPwns);
                if (numPwns > 0) {
                  redirectOnSuccess.value = true;
                  showPwnWarning.value = true;
                }
              })
              .catch((err) => {
                console.log("There was a problem: ", err);
              });
            // const { refreshToken } = response;
            // const { id } = response.user;
            // localStorage.setItem("tpm-jwt-refresh", refreshToken);
            // localStorage.setItem("tpm-uid", id);
            if (redirectOnSuccess.value == true) {
              showPwnWarning.value == true;
            } else {
              router.push({ name: "dashboard-analytics" });
              loading.value = false;
            }
          })
          .catch((error) => {
            snackbar({
              text: error,
              color: "error"
            });
            loading.value = false;
          });
      };

      return {
        isPasswordVisible,
        email,
        password,
        loading,
        redirectOnSuccess,
        showPwnWarning,
        handlePwnWarning,

        //socialLink,
        handleFormSubmit,

        // themeConfig
        appName: themeConfig.app.name,
        appLogo: themeConfig.app.logoDark,

        icons: {
          mdiEyeOutline,
          mdiEyeOffOutline
        }
        //showSnackbar
      };
    }
  });
</script>

<template>
  <div>
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner">
        <v-card class="auth-card">
          <!-- logo -->
          <v-card-title class="d-flex align-center justify-center py-7">
            <router-link to="/" class="d-flex align-center">
              <v-img
                :src="appLogo"
                max-height="30px"
                max-width="30px"
                height="30px"
                width="30px"
                alt="logo"
                contain
                class="me-3"
              ></v-img>

              <h2 class="text-2xl font-weight-semibold">
                {{ appName }}
              </h2>
            </router-link>
          </v-card-title>

          <!-- title -->
          <v-card-text>
            <p
              class="text-xl font-weight-semibold text--primary text-center mb-2"
            >
              Welcome to TrafficPM! 👋🏻
            </p>
            <p class="mb-2">Please sign-in to your account to get started</p>
          </v-card-text>

          <!-- login form -->
          <v-card-text>
            <v-form
              ref="loginForm"
              @submit.prevent="handleFormSubmit(email, password)"
            >
              <div class="mb-4">
                <label
                  for="email"
                  class="label-color font-weight-semibold d-block"
                >
                  Email
                </label>
                <v-text-field
                  id="email"
                  v-model="email"
                  dense
                  outlined
                  placeholder="john@example.com"
                  hide-details
                ></v-text-field>
              </div>
              <div class="mb-2">
                <label
                  for="password"
                  class="label-color font-weight-semibold d-block"
                >
                  Password
                </label>
                <v-text-field
                  id="password"
                  v-model="password"
                  dense
                  outlined
                  :type="isPasswordVisible ? 'text' : 'password'"
                  placeholder="············"
                  :append-icon="
                    isPasswordVisible
                      ? icons.mdiEyeOffOutline
                      : icons.mdiEyeOutline
                  "
                  hide-details
                  @click:append="isPasswordVisible = !isPasswordVisible"
                ></v-text-field>
              </div>
              <div class="d-flex align-center justify-end flex-wrap">
                <!-- <v-checkbox label="Remember Me" hide-details class="me-3 mt-1">
                </v-checkbox> -->

                <!-- forgot link -->
                <router-link
                  :to="{ name: 'account-forgot-password' }"
                  class="mt-1"
                >
                  Forgot Password?
                </router-link>
              </div>

              <v-btn
                block
                color="primary"
                type="submit"
                :loading="loading"
                :disabled="loading"
                class="mt-6"
              >
                Login
              </v-btn>
            </v-form>
          </v-card-text>

          <!-- create new account  -->
          <!-- <v-card-text
            class="d-flex align-center justify-center flex-wrap mt-2"
          >
            <span class="me-2"> New on our platform? </span>
            <router-link :to="{ name: 'account-register' }">
              Request access
            </router-link>
          </v-card-text> -->

          <!-- divider -->
          <!-- <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text> -->

          <!-- social links -->
          <!-- <v-card-actions class="d-flex justify-center">
          <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-1"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions> -->
        </v-card>
      </div>

      <!-- background triangle shape  -->
      <img
        class="auth-mask-bg"
        height="173"
        :src="
          require(`@/assets/images/misc/mask-${
            $vuetify.theme.dark ? 'dark' : 'light'
          }.png`)
        "
      />

      <!-- tree -->
      <v-img
        class="auth-tree"
        width="247"
        height="185"
        src="@/assets/images/misc/tree.png"
      ></v-img>

      <!-- tree  -->
      <v-img
        class="auth-tree-3"
        width="377"
        height="289"
        src="@/assets/images/misc/tree-3.png"
      ></v-img>
    </div>
    <div>
      <v-dialog v-model="showPwnWarning" persistent max-width="400">
        <v-card>
          <v-card-title class="text-h5">
            Alert: Password Blacklisted
          </v-card-title>
          <v-card-text>
            Our service has detected that you are attempting to use a
            blacklisted password. You will be redirected to the Change Password
            page. Please note that failure to change your password in a timely
            fashion will result in your account being locked. If you are using
            this password on other sites we recommend you change your password
            there as well. If you have any questions please contact the
            administrator.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="handlePwnWarning">
              I Understand
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<style lang="scss">
  @import "@/preset/preset/pages/auth.scss";
</style>
